import React from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';

function About() {
  return (
    <>
    <NavBar/>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <div className="bg-blue-300 min-h-screen p-8">
      <div className="max-w-3xl mx-auto bg-white p-8 rounded-lg shadow-md">
        <h1 className="text-blue-900 text-3xl font-bold mb-4 text-justify">About N9 Solution</h1>
        <p className="text-lg mb-6 text-justify">
          Welcome to N9 Solution, your premier destination for cutting-edge expertise in DevOps, AWS, Blockchain, and game development. At N9 Solution, we blend passion with proficiency, equipping individuals and organizations with the skills and strategies needed to thrive in today's rapidly evolving technological landscape.
        </p>
        <h2 className="text-blue-900 text-2xl font-bold mb-2 text-justify">Our Mission</h2>
        <p className="text-lg mb-6 text-justify">
          At N9 Solution, we are driven by a singular mission - to empower innovation. We believe in harnessing the power of technology to drive positive change and unlock new possibilities. Through our comprehensive training programs and strategic consultancy services, we aim to catalyze growth, foster innovation, and elevate success.
        </p>
        <h2 className="text-blue-900 text-2xl font-bold mb-2 text-justify">Our Focus Areas</h2>
        <ol className="list-decimal pl-6 mb-6 text-justify">
          <li>DevOps Excellence: DevOps is at the heart of modern software development, facilitating seamless collaboration and continuous delivery. Our DevOps training programs and consultancy services empower teams to streamline workflows, automate processes, and accelerate software delivery cycles.</li>
          <li>AWS Mastery: As a leading provider of cloud computing services, AWS offers unparalleled scalability, flexibility, and reliability. Our AWS training programs and consultancy services enable organizations to harness the full potential of AWS, leveraging cloud technologies to drive efficiency, innovation, and business growth.</li>
          <li>Blockchain Innovation: Blockchain technology is revolutionizing industries, from finance and healthcare to supply chain management and beyond. Our blockchain training programs and consultancy services equip individuals and organizations with the knowledge and skills needed to capitalize on this transformative technology, unlocking new opportunities and driving meaningful change.</li>
          <li>Game Development Expertise: Game development is a dynamic and rapidly evolving field, where creativity meets technology. Our game development training programs and consultancy services empower aspiring game developers and industry professionals alike to bring their visions to life, from concept to launch.</li>
          <li>Training Services for Students: We are dedicated to nurturing the next generation of IT professionals. Our training services for students provide comprehensive learning experiences, hands-on projects, and mentorship opportunities, preparing them for successful careers in the tech industry.</li>
        </ol>
        <h2 className="text-blue-900 text-2xl font-bold mb-2 text-justify">Our Promise</h2>
        <p className="text-lg mb-6 text-justify">
          When you choose N9 Solution, you're not just investing in training or consultancy services - you're investing in your future. We are committed to delivering excellence in everything we do, providing unparalleled expertise, personalized support, and tangible results. With N9 Solution by your side, the possibilities are endless.
        </p>
        <h2 className="text-blue-900 text-2xl font-bold mb-2 text-justify">Join Us</h2>
        <p className="text-lg mb-6 text-justify">
          Ready to embark on a journey of innovation and growth? Contact N9 Solution today to learn more about our training programs and consultancy services. Together, let's unlock the full potential of technology and achieve greatness.
        </p>
        <h2 className="text-blue-900 text-2xl font-bold mb-2 text-justify">Contact Us</h2>
        <p className="text-lg mb-6 text-justify">
          N9 Solution - Empowering Innovation Across DevOps, AWS, Blockchain, and Game Development. Let's build a brighter future together.
        </p>
      </div>
    </div>
    <Footer/>
    </>
  );
}
export default About;
